import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeftMenuModule } from './left-menu/left-menu.module';
import { LoginModule } from './pages/login/login.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './app.interceptor';
import { PagesModule } from './pages/pages.module';
import { KVideoModule } from './k-video/k-video.module';
import { SchedulingModule } from './pages/scheduling/scheduling.module';
import { CanvasModule } from './canvas/canvas.module';
import { ControlsModule } from './controls/controls.module';
import { ChatModule } from './chat/chat.module';
import { ChapalaMlsModule } from './chapala-mls/chapala-mls.module';
import { PlayBackComponent } from './record/play-back/play-back.component';
import { KpayModule } from './kpay/kpay.module';
import { ReadyComponent } from './record/ready/ready.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ContactsModule } from './pages/contacts/contacts.module';
import { AssetsModule } from './pages/assets/assets.module';
// import { CallManagementModule } from './pages/call-management/call-management.module';

@NgModule({
  declarations: [
    AppComponent,
    PlayBackComponent,
    ReadyComponent,

  ],
  imports: [
    LoginModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HeaderModule,
    LeftMenuModule,
    PagesModule,
    ChatModule,
    KVideoModule,
    SchedulingModule,
    StoreModule.forRoot({}), //We may
    CanvasModule,
    ControlsModule,
    ChapalaMlsModule,
    KpayModule,
    ClipboardModule,
    ContactsModule,
    AssetsModule,

    // CallManagementModule
  ],
  // providers: [{provide: APP_BASE_HREF, useValue : '/' }],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ]
})
export class AppModule { }
