import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { SVG } from '@svgdotjs/svg.js';
import { chatId } from 'src/app/chat/chat.selectors';
import { iAmManager } from 'src/app/pages/pages.selectors';
import { managerId, repId } from 'src/app/wss/wss.selectors';
import { SaveSVG } from '../canvas.actions';
import { canvasOpts } from '../canvas.selectors';
import { CanvasComponent } from '../canvas/canvas.component';

@Component({
  selector: 'white-board',
  templateUrl: './white-board.component.html',
  styleUrls: ['./white-board.component.scss']
})
export class WhiteBoardComponent extends CanvasComponent {
  signer_key: string | undefined

  @ViewChild('outContainer') outContainer: ElementRef | undefined;
  @ViewChild('textField') textField: ElementRef | undefined;
  container_height: number = 500;
  container_width: number = 500;
  container_top: number = 0;
  container_left: number = 0;

  error: string = ""
  bShowHeader: boolean = true
  chat_id: string | undefined

  msg_from_id: string | undefined
  bManager: boolean = false
  manager_id: string | undefined
  rep_id: string | undefined
  bNoHeader: boolean = false
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.removeInfoAndAnchors()
    this.removeMultiSelBoxes()
    let ns = this.setSize()
    setTimeout(() => {
      this.container_height = ns.height
      this.container_width = ns.width
      this.container_top = ns.top
      this.container_left = ns.left
      this.my_canvas.style.width = ns.width + "px"
      this.my_canvas.style.height = ns.height + "px"

    })
  }
  ngAfterViewInit() {
    console.log("white board")
    this.asset_id = "wb"

    this.subscriptions.push(this.chatState.select(chatId).subscribe(async (chat_id: string | undefined) => {
      this.chat_id = chat_id
    }))
    this.subscriptions.push(this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      this.bManager = manager
      if (manager) {
        this.msg_from_id = this.manager_id
      } else {
        this.msg_from_id = this.rep_id
      }
    }))
    this.subscriptions.push(this.wssState.select(repId).subscribe(async (id: string | undefined) => {
      this.rep_id = id
      if (!this.bManager) {
        this.msg_from_id = this.rep_id
      }
    }))
    this.subscriptions.push(this.wssState.select(managerId).subscribe(async (id: string | undefined) => {
      this.manager_id = id
      if (this.bManager) {
        this.msg_from_id = this.manager_id
      }
    }))
    this.subscriptions.push(this.canvasStore.select(canvasOpts).subscribe((opts: any) => {

      if (opts) {
        if (opts.bContract) {
          this.bNoHeader = true;
          return
        }
        this.bNoHeader = false
      }
    }))
    let ns = this.setSize()
    setTimeout(() => {
      this.container_height = ns.height
      this.container_width = ns.width
      this.container_top = ns.top
      this.container_left = ns.left
    })
    if (this.divContainer) {
      this.page_svg = SVG()
      this.addCanvasToDiv(true, this.divContainer.nativeElement, undefined, ns.width, ns.height)
    }
  }

  setSize() { //keps a .75 aspect ratio
    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.divContainer && this.outContainer) {
      out_w = this.outContainer.nativeElement.clientWidth
      out_h = this.outContainer.nativeElement.clientHeight
      width = out_w

      height = out_w * .5625
      if (height > out_h - 2) {

        width = out_h * 1 / .5625
        left = (out_w - width) / 2
        if (left < 1) {
          width -= (1 - left * 2)
          left = 1;
        }
        height = out_h - 12
        top = 0

      } else {

        width = out_w - 12
        top = (out_h - height) / 2
        left = 1

      }
      console.log("out_w " + out_w + " out_h " + out_h + " => left " + left + " width " + width + " top " + top + " height " + height)


    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }
  addToChat(role: string) {
    let msg = {
      chat_id: this.chat_id,
      chat_msg: {
        chat_svg: this.draw_tool.svg(),
        bbox: this.draw_tool.bbox(),
        time: new Date().getTime(),
        id: this.msg_from_id
      }
    }
    this.wss_serivce.sendMessageToMembersAndMe(msg) //it will be picked up by last msg in chat.service

  }

}
