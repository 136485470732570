<div class="s_out" #outComponent>
  <video [muted]="true" #videoElement class="container" [style.height.px]="container_height" [style.top.px]="container_top" [style.width.px]="container_width" [style.left.px]="container_left" autoPlay autoplay playsinline>
  </video>

  <div class="container" #divContainer [style.height.px]="container_height" [style.top.px]="container_top" [style.width.px]="container_width" [style.left.px]="container_left">
    <!-- MAKE SURE NOT TO PUT ANY THING INSIDE -->
  </div>

  <div *ngIf="rv_l" class="rv_l" [style.width.px]="rv_l">
    <!-- <div class="not_v">
      Not viewable to caller
    </div>
    <div class="not_v not_v_tx">
      Your screen is wider than the callers
    </div> -->
  </div>
  <div *ngIf="rv_r1" class="rv_l rv_r" [style.left.px]="rv_r1" [style.width]="rv_rw">
    <!-- <div class="not_v">
      Not viewable to caller
    </div>
    <div class="not_v not_v_tx">
      Your screen is wider than the callers
    </div> -->
  </div>
</div>
