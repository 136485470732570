import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KPayMainComponent } from './kpay/main/main.component';
import { AssetsComponent } from './pages/assets/assets/assets.component';
import { CallManagementComponent } from './pages/call-management/call-management/call-management.component';
// import { ContactsComponent } from './pages/contacts/contacts/contacts.component';
import { InCallComponent } from './pages/in-call/in-call/in-call.component';
import { LoginComponent } from './pages/login/login.component';
import { PagesModule } from './pages/pages.module';
import { ProfileComponent } from './pages/profile/profile/profile.component';
import { SchedulingComponent } from './pages/scheduling/scheduling/scheduling.component';
import { SingupComponent } from './pages/singup/singup.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: "Login" } },
  { path: 'signup', component: SingupComponent, data: { title: "Signup" } },
  { path: 'call', component: InCallComponent, data: { title: "Live Call" } },
  { path: 'profile', component: ProfileComponent, data: { title: "Profile" } },
  { path: 'assets', component: AssetsComponent, data: { title: "Assets" } },
  { path: 'calendar', component: SchedulingComponent, data: { title: "Calendar" } },
  // { path: 'contacts', component: ContactsComponent, data: { title: "Contacts" } },
  { path: 'hub', component: CallManagementComponent, data: { title: "Call Hub" } },
  { path: 'kpay', component: KPayMainComponent, data: { title: "Payments" } },

  // { path: 'reports', component: ReportingComponent, data: { title: "Reports" } },

];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
