import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { getQueryParameter } from './utils'
import { v4 as uuidv4 } from 'uuid';
import { Store } from '@ngrx/store';
import { WSSState } from './wss/wss.model';
import { SessionExpired, SessionId } from './wss/wss.actions';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AppInterceptor implements HttpInterceptor {

  host: string | null;
  //  protocol: string = "https://"
  protocol: string = "https://"
  sessionId: string
  port: string = ""
  constructor(
    private wssState: Store<WSSState>,
  ) {
    console.log("app interceptor")
    let session_id = getQueryParameter('session_id')
    if (session_id) {
      this.sessionId = session_id
    } else {
      this.sessionId = uuidv4();
    }
    this.wssState.dispatch(new SessionId(this.sessionId))

    let token = getQueryParameter('token')
    if (token) {
      this.sessionId = token
    }

    let host = getQueryParameter("host")
    if (host) {
      localStorage.setItem('host', host)
    }
    this.host = localStorage.getItem('host')
    //  this.host = "slim.aci.us"
    if (!this.host) {
      this.host = location.hostname
    }

    if (this.host.startsWith("local")) {
      // this.host = location.hostname
      this.protocol = location.protocol + "//"
      this.port = ":80"
    } else {
      this.protocol = "https://"
      this.port = "" //":443"
    }
    console.log("Intercepto host " + this.host)
    let url = this.fixURL("/")
    // this.toolsStore.dispatch(new SetURL(url))
    // this.authState.dispatch(new SetSessionId(this.sessionId))
    // this.authState.select(sessionId).subscribe((session_id: string) => {
    //   this.sessionId = session_id;
    // })

  }


  fixURL(url: string) {
    let new_url;
    if (url.startsWith('http') == false && url.startsWith('/assets') == false) {
      if (location.port == "4207"
        || location.port == "4205"
        || location.port == "4206"
        || location.port == "4200") {
        //new_url = this.protocol + this.host + this.port + '/api' + url;
        if (url.startsWith('assets')) {
          new_url = this.protocol + this.host + '/' + url;

        } else {
          new_url = this.protocol + this.host + '/api' + url;
        }
      } else {
        if (url.startsWith('assets')) {
          new_url = location.origin + '/' + url;
        } else {
          new_url = location.origin + '/api' + url;
        }
      }
    } else {
      new_url = url;
    }
    return new_url;
  }

  pending_req: number = 0;
  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    console.log("my-interceptor " + request.url)

    if (!request.url.startsWith("https://k-recorder")) {
      let new_url: string = this.fixURL(request.url);

      // console.log("**URL--> " + new_url + " pending " + this.pending_req)
      this.pending_req++


      if (request.url.startsWith('http')) {
        request = request.clone({ url: new_url, headers: request.headers.set('Access-Control-Allow-Origin', '*') });

      } else {
        //add the session token
        request = request.clone({ url: new_url, headers: request.headers.set('Authorization', 'Bearer ' + this.sessionId) });
      }
    }
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          this.pending_req--
          // console.log("**URL<-- " + new_url + " pending " + this.pending_req)
        }
        return event;
      }, error => {
        if (error.status == 408 || error.status == 401) {
          if (error.error == "Invalid password") {
            return
          }
          this.wssState.dispatch(new SessionExpired())
          // this.toastr.error("Session Timed out")
          //    console.log('Logout');
          //    this.router.navigateByUrl('/home');
        } else {
          console.error('NICE ERROR' + JSON.stringify(error));
          //
        }
        // map((event: any) => {
        //   if (event instanceof HttpResponse) {
        //     console.log('event--->>>', event);
        //   }
        //
        //   if (event instanceof HttpErrorResponse) {
        //     if (event.status === 401) {
        //       console.log('error--->>>', event);
        //     }
        //
        //   }
        return event;
      }));
  }

}
