import { Store } from '@ngrx/store';
import { PagesState } from 'src/app/pages/pages.model';
import { WSSState } from 'src/app/wss/wss.model';
import { callId } from 'src/app/wss/wss.selectors';
import { AddToUndo, ClearSel } from '../canvas.actions';
import { strokeColor, fillColor, textColor, pencilWidth, selectedTool, textFont, textSize, textWeight, tools, selectedInput, signers, selSignerKey } from '../canvas.selectors';
import { CanvasService } from '../canvas.service';
import { CanvasComponent } from '../canvas/canvas.component';


export class Draw {
  canvas: any

  selected_id: string = "x"

  startX: number = 0
  startY: number = 0

  fill_color: string = "rgba(255,255,0,0.53)"
  stroke_color: string = "rgba(255,165,0,0.53)"

  pencil_width: number = 5
  draw_tool: any
  mouse_down_at: number = 0

  text_color: string = "rgba(54,255,0,0.53)"
  text_font: string = "OpenSans"
  text_weight: string = "Regular"
  text_size: string = "12px"

  selected_input: string = ""

  sel_tool_name: string = "none"
  tools: any

  move: string = "no"
  top_left_anchor: any
  top_right_anchor: any
  bottom_left_anchor: any
  bottom_right_anchor: any
  moving_anchor: any
  top_line: any
  right_line: any
  left_line: any
  bottom_line: any


  back_div: any
  top_new_point: any
  right_new_point: any
  left_new_point: any
  bottom_new_point: any



  x1: number = 0
  y1: number = 0
  x2: number = 0
  y2: number = 0


  mv_x1: number = 0
  mv_y1: number = 0
  mv_x2: number = 0
  mv_y2: number = 0


  pointer_id: any //to capture the mouse

  hover_div: any
  move_undo_params: any //do it after we are done with the move if we did move
  call_id: string | undefined
  parent_canvas!: CanvasComponent

  signers: any
  signer_key: string | undefined

  constructor(
    draw_tool: any,
    parent_canvas: CanvasComponent,
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public wssState: Store<WSSState>,
  ) {
    this.parent_canvas = parent_canvas
    this.canvas = parent_canvas.my_canvas
    this.canvasStore.select(tools).subscribe((tools: any) => {
      this.tools = tools
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
    })


    this.canvasStore.select(selectedTool).subscribe((tool_name: string) => {
      this.sel_tool_name = tool_name
      if (this.sel_tool_name != "none") {
        this.canvasStore.dispatch(new ClearSel())
        // this.removeBox()
      }
    })
    this.canvasStore.select(fillColor).subscribe((fill_color: string) => {
      this.fill_color = fill_color
    })
    this.canvasStore.select(pencilWidth).subscribe((pencil_width: number) => {
      this.pencil_width = pencil_width
    })
    this.canvasStore.select(strokeColor).subscribe((stroke_color: string) => {
      this.stroke_color = stroke_color
    })
    this.canvasStore.select(textColor).subscribe((text_color: string) => {
      this.text_color = text_color
    })


    this.canvasStore.select(selectedInput).subscribe((selected_input: string) => {
      this.selected_input = selected_input
    })


    this.draw_tool = draw_tool
    setTimeout(() => {
      this.initClass()
    })

    this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
    })
    this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      this.signer_key = signer_key
    })
  }

  //****************************************************************************
  isSelected(id: string) {
    if (this.selected_id == id) {
      return true;
    }
    return false
  }

  initClass() {
    console.log("sublcassed")
    this.canvasStore.select(textWeight).subscribe((text_weight: string) => {
      this.text_weight = text_weight
    })
    this.canvasStore.select(textSize).subscribe((text_size: string) => {
      this.text_size = text_size
    })
    this.canvasStore.select(textFont).subscribe((text_font: string) => {
      this.text_font = text_font
    })
  }
  dump(lbl: string) {
    console.log(lbl)
    let children = this.draw_tool.children()
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      let dataset = group.node.dataset
      console.log(JSON.stringify(dataset))
    }
  }
  findGroupById(id: string) {
    let children = this.draw_tool.children()
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      let dataset = group.node.dataset
      let group_id = dataset['id']
      if (group_id == id) {
        // console.log("found " + id)
        return group;
      }
    }
  }
  findElementsById(id: string) {
    let children = this.draw_tool.children()
    let elelements: any[] = []
    for (let i = 0; i < children.length; i++) {
      let group = children[i]
      let dataset = group.node.dataset
      let group_id = dataset['id']
      if (group_id == id) {
        // console.log("found " + id)
        elelements.push(group);
      }
    }
    return elelements;
  }
  getArrayFromStr(spoints: string) {
    let poly_array: number[] = []
    // console.log("poly  set rect " + spoints)
    if (spoints) {
      let points = spoints.split(' ')

      for (let i = 0; i < points.length; i++) {
        let point = points[i]
        let parts = point.split(',')
        for (let j = 0; j < parts.length; j++) {
          let val = parseFloat(parts[j])
          poly_array.push(val)
        }
      }
      // console.log("poly  set rec  " + JSON.stringify(poly_array))
    } else {
      console.error("no spoints")
    }
    return poly_array

  }
  getBoxFromArray(points: number[]) {
    let left = 10000
    let right = 0
    let top = 10000
    let bottom = 0

    for (let i = 0; i < points.length; i++) {
      let p = points[i]
      if (i % 2 == 0) {
        if (p < left) {
          left = p
        }
        if (p > right) {
          right = p
        }
      } else {
        if (p < top) {
          top = p
        }
        if (p > bottom) {
          bottom = p
        }
      }
    }
    return { left: left, top: top, bottom: bottom, right: right }
  }
  processMsg(msg: any) {
    this.undo(msg)
    if (msg.canvas_width) {
      let vb = {
        width: msg.canvas_width,
        height: msg.canvas_height
      }
      this.setMinSize(msg.id, vb)
    } else {
      this.setMinSize(msg.id);
    }
  }
  select(selected_id: string) { //sublcassed

  }

  unselect(selected_id: string) { //sublcassed
    this.removeBox()
  }
  undo(msg: any) {
    if (msg.undo == 'erase') {
    } else if (msg.undo == 'move') {
    } else if (msg.undo == 'new') {
    }
  }

  capturePointer() {

  }
  releasePointer() {

  }
  //Called by the subclass
  startMoveAll(obj: any, $event: any) {
    let me = this
    let count: number = 0
    function onBackUp(this: any, $event: any) {
      console.log("#MV DL  back  up")
      me.move = "no"
      me.canvas.onpointermove = null;
      if (me.pointer_id) {
        me.canvas.releasePointerCapture(me.pointer_id)
        me.pointer_id = undefined
        me.doneMoving(this, $event)
        console.log("#MV DL doneMoving back  up")
      }

    }
    function onMoveAll(this: any, $event: any) {
      if ($event.buttons == 0) {
        return
      }
      if (me.startX < 0) {
        me.startX = $event.offsetX
        me.startY = $event.offsetY
      }
      let delta_x = $event.offsetX - me.startX
      let delta_y = $event.offsetY - me.startY
      // console.log("#dr move all " + delta_x + " " + delta_y)
      console.log("#draw sbm all  ex " + $event.offsetX + " ey " + $event.offsetY)
      if (++count == 2) {
        console.log("#draw sbm check")
      }
      me.setBoxAndMove(me.x1 + delta_x, me.y1 + delta_y, me.x2 + delta_x, me.y2 + delta_y)
    }
    this.move = "all"
    this.x1 = parseFloat(this.top_left_anchor.style.left)
    this.x2 = parseFloat(this.bottom_right_anchor.style.left)
    this.y1 = parseFloat(this.top_left_anchor.style.top)
    this.y2 = parseFloat(this.bottom_right_anchor.style.top)

    this.mv_x1 = this.x1
    this.mv_y1 = this.y1
    this.mv_x2 = this.x2
    this.mv_y2 = this.y2
    console.log("#MV DL set up mv_ start mova all")

    this.startX = -1
    this.startY = -1
    this.pointer_id = $event.pointerId
    if (this.pointer_id) {
      try {
        me.canvas.setPointerCapture(this.pointer_id)
        me.canvas.onpointermove = onMoveAll
        me.canvas.onpointerup = onBackUp
        console.log("#drm start all")
      } catch (e) {
        console.error(e)
      }
    }
  }
  scaleAndMove(id: string, x1: number, y1: number, x2: number, y2: number) { //sublclased
  }
  prepareMove(_arrow_id: string, _$event?: any) { //subclass it
  }

  setBoxAndMove(x1: number, y1: number, x2: number, y2: number) {
    this.mv_x1 = x1
    this.mv_y1 = y1
    this.mv_x2 = x2
    this.mv_y2 = y2
    console.log("#MV DL set up mv_ setbox and move")

    this.setBox(x1, y1, x2, y2)
    if (this.top_left_anchor) {
      console.log("#draw --> " + x1 + " " + y1 + " " + x2 + " " + y2)
      let id = this.top_left_anchor.dataset['id']
      this.scaleAndMove(id, x1, y1, x2, y2)
    }
  }
  setBox(x1: number, y1: number, x2: number, y2: number) {

    if (this.back_div) {
      let w = x2 - x1
      if (w > 0) {
        this.back_div.style.left = x1 + "px"
        this.back_div.style.width = w + "px"
      } else {
        w = x1 - x2
        this.back_div.style.left = x2 + "px"
        this.back_div.style.width = w + "px"

      }
      let h = y2 - y1
      if (h > 0) {
        this.back_div.style.top = y1 + "px"
        this.back_div.style.height = h + "px"
      } else {
        h = y1 - y2
        this.back_div.style.top = y2 + "px"
        this.back_div.style.height = h + "px"
      }
    }
    if (this.top_left_anchor) {
      this.top_left_anchor.style.left = (x1 - 5) + "px"
      this.top_left_anchor.style.top = (y1 - 5) + "px"


      this.top_right_anchor.style.left = (x2 - 5) + "px"
      this.top_right_anchor.style.top = (y1 - 5) + "px"


      this.bottom_left_anchor.style.left = (x1 - 5) + "px"
      this.bottom_left_anchor.style.top = (y2 - 5) + "px"


      this.bottom_right_anchor.style.left = (x2 - 5) + "px"
      this.bottom_right_anchor.style.top = (y2 - 5) + "px"


      this.top_line.style.width = (x2 - x1) + "px"
      this.top_line.style.left = x1 + "px"
      this.top_line.style.top = y1 + "px"

      this.bottom_line.style.width = (x2 - x1) + "px"
      this.bottom_line.style.left = x1 + "px"
      this.bottom_line.style.top = y2 + "px"

      let h = (y2 - y1)
      let t = y1
      if (h < 0) {
        h *= -1
        t = y2
      }
      this.left_line.style.height = h + "px"
      this.left_line.style.left = x1 + "px"
      this.left_line.style.top = t + "px"

      this.right_line.style.height = h + "px"

      this.right_line.style.left = x2 + "px"
      this.right_line.style.top = t + "px"
    }

    if (this.left_new_point) {
      this.left_new_point.style.top = ((y2 + y1) / 2 - 5) + "px"
      this.left_new_point.style.left = (x1 - 5) + "px"

      this.right_new_point.style.top = ((y2 + y1) / 2 - 5) + "px"
      this.right_new_point.style.left = (x2 - 5) + "px"

      this.top_new_point.style.left = ((x2 + x1) / 2 - 5) + "px"
      this.top_new_point.style.top = (y1 - 5) + "px"

      this.bottom_new_point.style.left = ((x2 + x1) / 2 - 5) + "px"
      this.bottom_new_point.style.top = (y2 - 5) + "px"

    }


  }
  removeBox() {
    if (this.top_left_anchor) {
      this.canvas.removeChild(this.top_left_anchor)
      this.top_left_anchor = undefined
    }
    if (this.top_right_anchor) {
      this.canvas.removeChild(this.top_right_anchor)
      this.top_right_anchor = undefined
    }
    if (this.bottom_left_anchor) {
      this.canvas.removeChild(this.bottom_left_anchor)
      this.bottom_left_anchor = undefined
    }
    if (this.bottom_right_anchor) {
      this.canvas.removeChild(this.bottom_right_anchor)
      this.bottom_right_anchor = undefined
    }
    if (this.left_line) {
      this.canvas.removeChild(this.left_line)
      this.left_line = undefined
    }
    if (this.right_line) {
      this.canvas.removeChild(this.right_line)
      this.right_line = undefined
    }
    if (this.top_line) {
      this.canvas.removeChild(this.top_line)
      this.top_line = undefined
    }
    if (this.bottom_line) {
      this.canvas.removeChild(this.bottom_line)
      this.bottom_line = undefined
    }
    if (this.back_div) {
      this.canvas.removeChild(this.back_div)
      this.back_div = undefined
    }

    if (this.top_new_point) {
      this.canvas.removeChild(this.top_new_point)
      this.top_new_point = undefined
      this.canvas.removeChild(this.left_new_point)
      this.left_new_point = undefined
      this.canvas.removeChild(this.bottom_new_point)
      this.bottom_new_point = undefined
      this.canvas.removeChild(this.right_new_point)
      this.right_new_point = undefined
    }
    if (this.hover_div) {
      this.canvas.removeChild(this.hover_div)
      this.hover_div = undefined
    }
  }

  //sublcassed

  //I think i will need this for the poly
  /*  moveAndSize(sarray: string, x1: number, y1: number, x2: number, y2: number) {//sublclased

      let o_x1 = this.x1
      let o_x2 = this.x2
      let o_y1 = this.y1
      let o_y2 = this.y2

      // console.log("#draw o x1 " + o_x1 + " y1 " + o_y1 + "  x2 " + o_x2 + " y2 " + o_y2)
      // console.log("#draw n x1 " + x1 + " y1 " + y1 + "  x2 " + x2 + " y2 " + y2)
      let scale_x = (x2 - x1) / (o_x2 - o_x1)
      let scale_y = (y2 - y1) / (o_y2 - o_y1)


      let points = sarray.split(' ') //some times there are pairs
      let scaled_points: number[] = []
      let c = 0;
      let left = 10000
      let right = 0
      let top = 10000
      let bottom = 0
      for (let i = 0; i < points.length; i++) {
        let spair = points[i]
        let spair_parts = spair.split(',')
        for (let j = 0; j < spair_parts.length; j++) {
          let sp = spair_parts[j]
          let p = parseFloat(sp)

          if (c % 2 == 0) { //x
            let px = p - x1
            px *= scale_x
            p = px + x1
            if (p < left) {
              left = p
            }
            if (p > right) {
              right = p
            }
          } else { //y
            let py = p - y1
            py *= scale_y
            p = py + y1
            if (p < top) {
              top = p
            }
            if (p > bottom) {
              bottom = p
            }
          }
          c++
          scaled_points.push(p)
        }
      }
      let dx = left - x1
      let dy = top - y1
      if (y1 > y2) {
        dy = top - y2
      }
      if (x1 > x2) {
        dx = left - x2
      }

      // console.log("#draw left " + left + " top " + top + " scale_x " + scale_x + " scale_y " + scale_y + "  dx " + dx + " dy " + dy)


      // console.log("@MS in " + sarray)
      // console.log("@MS scaled " + JSON.stringify(scaled_points))



      let new_points: number[] = []
      for (let i = 0; i < scaled_points.length; i++) {
        let p = scaled_points[i]
        if (i % 2 == 0) { //x
          p -= dx
        } else {
          p -= dy
        }
        new_points.push(p)
      }
      // console.log("@MS out " + JSON.stringify(new_points))
      return new_points
    }
    onHover(element_id: string, box: any, k_type: string) {

      if (this.isSelected(element_id) || this.sel_tool_name != "none") {
        return
      }


      let me = this
      function onMouseDown(this: any, $event: any) {
        $event.stopPropagation()
        let id = this.dataset['id']
        let k_type = this.dataset['k_type']
        if (!me.isSelected(id)) {
          if (me.sel_tool_name == "none") {
            console.log("poly nowt selected, selecting it " + id)
            me.canvasStore.dispatch(new SelectId(id, $event.shiftKey, k_type))
          }
        }
        this.onpointermove = undefined
        this.onpointerleave = undefined
        if (me.hover_div) {
          me.canvas.removeChild(me.hover_div)
          me.hover_div = undefined
        }
      }
      function onMouseLeave(this: any, $event: any) {
        if (me.hover_div) {

          // let id = this.dataset['id']
          // if (id != element_id) {
          console.log("remove hover_div " + element_id)
          me.canvas.removeChild(me.hover_div)
          me.hover_div = undefined
          // }
        }
      }

      if (!this.hover_div) {
        this.hover_div = document.createElement('div')
        this.hover_div.onpointerdown = onMouseDown
        this.hover_div.onpointerleave = onMouseLeave
        this.canvas.appendChild(this.hover_div)
      }
      let w = box.right - box.left
      let h = box.bottom - box.top
      this.hover_div.dataset['id'] = element_id
      this.hover_div.dataset['k_type'] = k_type
      this.hover_div.style.left = box.left + "px"
      this.hover_div.style.top = box.top + "px"
      this.hover_div.style.width = w + "px"//page.style.width
      this.hover_div.style.height = h + "px"//page.style.height
      this.hover_div.style.position = "absolute"
      // this.hover_div.style.background = "red"

      this.hover_div.style.border = "1px solid #FFBA55"
      console.log("create hover_div " + this.hover_div.dataset['id'] + " " + JSON.stringify(box))
    }
    */
  addPolyPoint(obj: any) { //subclassed
  }
  getBox(id: string) { //subclassed for mullti select

  }
  movePolyPoints(poly: any, delta_x: number, delta_y: number) {
    let spoints = poly.attr('points')
    let points = this.getArrayFromStr(spoints)
    let new_points: number[] = []
    for (let i = 0; i < points.length; i += 2) {
      let x = points[i] + delta_x
      let y = points[i + 1] + delta_y
      new_points.push(x)
      new_points.push(y)
    }
    poly.attr('points', new_points)
    return new_points
  }

  moveDelta(id: string, delta_x: number, delta_y: number) {//subclassed for mullti select

  }
  doneMoving(obj: any, $event: any) {
    let d = (this.x1 - this.mv_x1) * (this.x1 - this.mv_x1)
    d += (this.x2 - this.mv_x2) * (this.x2 - this.mv_x2)
    d += (this.y1 - this.mv_y1) * (this.y1 - this.mv_y1)
    d += (this.y2 - this.mv_y2) * (this.y2 - this.mv_y2)

    if (d > 10 && this.move_undo_params) {
      let data = this.move_undo_params.data
      this.canvasStore.dispatch(new AddToUndo({
        undo: this.move_undo_params.undo,
        k_type: this.move_undo_params.k_type,
        id: this.move_undo_params.id,
        data: data
      }))
    }
  }
  pasteSVG(_svg: any, _x: number, _y: number) {

  }
  fromSVG(_obj: any) {

  }
  setPencilSize(id: string, size: number) {

  }
  setFont(id: string, font: string) {

  }
  setFontWeight(id: string, weight: string) {

  }
  setFontSize(id: string, size: string) {

  }
  setColor(_id: string, color: string) { //subclased

  }
  preSelect(_id: string, _k_type: string) {

  }
  newObj(left: number, top: number, right: number, bottom: number, sel_tool_name?: string) {

  }
  setMinSize(id: string, vb?: any) { }

}
