<div class="out">

  <asset *ngIf="asset || bShowAPI" [asset]="asset" class="content asset"></asset>

  <video-canvas class="content" *ngIf="share_stream && !asset" roll="share"></video-canvas>

  <div *ngIf="call_id" class="images" [ngClass]="{ in_bar:bShowProgress}">
    <div cdkDrag class="round" *ngIf="caller_id">
      <k-video role="caller" screen="in-call-asset" class="full" [small]="true">
      </k-video>
    </div>
    <div cdkDrag class="round" *ngIf="rep_id">
      <k-video role="rep" screen="in-call-asset" class="full" [bMirrow]="my_id==rep_id" [small]="true">
      </k-video>
    </div>
    <div cdkDrag class="round" *ngIf="manager_id">
      <k-video role="manager" screen="in-call-asset" class="full" [bMirrow]="my_id==manager_id" [small]="true">
      </k-video>
    </div>
  </div>
  <assets-header *ngIf="share_stream && !asset" [bSharingScr]="true" class="hdr"> </assets-header>

</div>
