import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatState } from 'src/app/chat/chat.model';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { checkVideo, streams } from 'src/app/k-video/k-video.selectors';
import { PagesState } from 'src/app/pages/pages.model';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSState } from 'src/app/wss/wss.model';
import { WSSService } from 'src/app/wss/wss.service';


import { CanvasService } from '../canvas.service';
import { MetaPageService } from '../meta-page.service';
import { fitImgInTarget } from 'src/app/utils';
import { CanvasComponent } from '../canvas/canvas.component';
import { ShowDrawTools } from '../canvas.actions';

@Component({
  selector: 'video-canvas',
  templateUrl: './video-canvas.component.html',
  styleUrls: ['../canvas/canvas.component.scss',
    '../white-board/white-board.component.scss',
    './video-canvas.component.scss']
})
export class VideoCanvasComponent extends CanvasComponent {
  video_roll: string = ""
  bMirrow: boolean = false

  @ViewChild('outComponent') outComponent: ElementRef | undefined
  @ViewChild('divContainer') container: ElementRef | undefined
  @ViewChild('videoElement') video: ElementRef | undefined;
  container_height: number = 500;
  container_width: number = 500;
  container_top: number = 0;
  container_left: number = 0;
  bSmall: boolean = false
  streams: any
  share_stream: MediaStream | undefined
  @Input() set roll(r: string) {
    if (r) {
      this.video_roll = r
      this.bMirrow = (this.video_roll == 'me' || this.video_roll == "rep")

    } else {
      this.video_roll = ""
    }
    this.setVideoStream()
  }
  @Input() rv_l: number = 0

  @Input() set rv_r(r: number) {
    this.rv_r1 = r
    this.rv_rw = "calc(100% - " + r + "px)"
  }
  rv_r1: number = 0;
  rv_rw: string = "0"

  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public wss_serivce: WSSService,
    public chatState: Store<ChatState>,
    public tools_service: ToolService,
    public meta_server: MetaPageService,
    private kVideoState: Store<KVideoState>,

  ) {
    super(canvasStore, wssState, wss_service, canvas_service, pagesState, wss_serivce, chatState, tools_service, meta_server);
    console.log("video-canvas")
  }
  ngAfterViewInit() {

    let me = this
    if (this.video) {

      this.video.nativeElement.addEventListener('loadeddata', function($event: any) {
        console.log("Loaded videoHeight " + $event.srcElement.videoWidth + " " + $event.srcElement.videoHeight);
        me.setCanvas()
      })
    }
    if (this.video_roll == "kuula") {
      this.setCanvas()
    }

    this.subscriptions.push(this.kVideoState.select(checkVideo).subscribe((check: any | undefined) => {
      if (check) {
        if (check.role == "local_share" || check.role == "remote_share" && this.share_stream) {
          delete this.share_stream
        }
      }
    }))
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))
      let share_stream = streams["local_share"]
      if (!share_stream) {
        share_stream = streams["remote_share"]
      }

      if (share_stream && !this.share_stream) {
        this.canvasStore.dispatch(new ShowDrawTools(true))
        let vts = share_stream.getVideoTracks()
        if (vts.length > 0) {
          this.share_stream = share_stream
          let interval = setInterval(() => {
            if (this.video) {
              let newStream = new MediaStream()
              newStream.addTrack(vts[0])
              this.video.nativeElement.srcObject = newStream
              this.video.nativeElement.volume = 0
              clearInterval(interval)
            }
          }, 100)
        }
      } else if (this.share_stream) {
        if (this.share_stream && !share_stream) {
          this.share_stream = share_stream //This will remove the share element
        }
      } else {
        this.streams = streams
        this.setVideoStream()
      }

    }))
  }
  @HostListener('window:resize', ['$event'])
  onResize(_event?: any) {
    this.setCanvas()
  }
  setVideoStream() {
    if (this.video) {
      if (this.video_roll && this.streams && this.streams[this.video_roll]) {
        this.video.nativeElement.srcObject = this.streams[this.video_roll]
      } else {
        this.video.nativeElement.srcObject = undefined
      }
    }
  }
  setCanvas() {
    setTimeout(() => {
      let ns = this.setVSize()

      this.container_height = ns.height
      this.container_width = ns.width
      this.container_top = ns.top
      this.container_left = ns.left

      if (this.container) {
        this.addCanvasToDiv(true, this.container.nativeElement, this.page_svg, ns.width, ns.height)
      }
    })
  }
  setVSize() { //keps a .75 aspect ratio

    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.outComponent && this.container) {
      // if (this.video) {
      this.bSmall = this.outComponent.nativeElement.clientHeight < 200
      out_w = this.outComponent.nativeElement.clientWidth
      out_h = this.outComponent.nativeElement.clientHeight
      height = out_h

      let img_w = 640//this.video.nativeElement.videoWidth
      let img_h = 480//this.video.nativeElement.videoHeight
      if (this.video_roll == "kuula") {

        return {
          height: out_h,
          width: out_w,
          top: 0,
          left: 0
        }
      } else if (this.video_roll == "share" && this.video) {
        img_w = this.video.nativeElement.videoWidth
        img_h = this.video.nativeElement.videoHeight
      }
      let rv = fitImgInTarget(img_w, img_h, out_w, out_h)

      return rv;
      // }
    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }
  scalePointsToViewBox(poly_array: number[], viewBox: any) { //used after a move\
    if (this.bNoScale) {
      return poly_array;
    }
    let new_points: number[] = []
    console.log("poly set anchors ")
    for (let i = 0; i < poly_array.length; i += 2) {
      let x1: number = poly_array[i]
      if (this.video_roll == "kuula") {
        /*          ***********-*-*************
            viewBox            |  | my_canvas *
          ------------         |  |           *
          |    x     |         |  |           *
          ------------         |  |           *
                    *          |  |           *
                    ************-*-************
        */
        let view_box_scaled_width = viewBox.width * this.my_canvas.clientHeight / viewBox.height
        x1 = x1 * view_box_scaled_width / viewBox.width
        let offset_x = (view_box_scaled_width - this.my_canvas.clientWidth) / 2
        x1 -= offset_x

        /*  -----
            |   |    -----***-----
            |   |    |    * *    |
            |   |    |    * *    |
            |   |    -----***-----
            -----
          viewBox
        */

      } else {
        x1 = this.scaleXToWidth(poly_array[i], viewBox.width)
      }

      new_points.push(x1)
      new_points.push(this.scaleYtoHeight(poly_array[i + 1], viewBox.height))
    }
    return new_points
  }

}
