<div class="out">
  <assets-header *ngIf="!preview" class="hdr"> </assets-header>
  <div class="body" [ngClass]="{b_prev:preview}" *ngIf="_asset">

    <images class="out" *ngIf="_asset.type=='image'" [asset]="_asset"></images>
    <matterport class="out" *ngIf="_asset.type=='matterport'" [asset]="_asset" [id]="_asset.url"></matterport>
    <uploaded-video class="out" *ngIf="_asset.type=='uploaded' || _asset.type=='video'" [asset]="_asset"></uploaded-video>
    <!-- <view-pdf *ngIf="_asset.type=='pdf'" [asset]="_asset" class="out">
  </view-pdf> -->

    <app-youtube class="out" *ngIf='_asset.type == "youtube"' [asset]="_asset">
    </app-youtube>

    <rep-pdf-canvas *ngIf="_asset.type=='pdf'||_asset.type=='contract'" [asset]="_asset" class="out">
    </rep-pdf-canvas>

    <white-board class="out" *ngIf="_asset.type=='white_board'"></white-board>
    <video-canvas class="out" *ngIf="_asset.type=='video_canvas'" [roll]="_asset.roll"></video-canvas>
    <app-kuula class="out" *ngIf="_asset.type=='kuula'" [asset]="_asset"> </app-kuula>

    <iframe class="out iframe" *ngIf="_asset.type=='iframe'" [src]="sanitized_url"> </iframe>

  </div>
  <api-wnd *ngIf="bShowAPI" class="body"></api-wnd>

</div>
