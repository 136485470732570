import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { WSSService } from 'src/app/wss/wss.service';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { CanvasComponent } from 'src/app/canvas/canvas/canvas.component';
import { canvasOpts, saveSVG } from 'src/app/canvas/canvas.selectors';
import { ToolService } from 'src/app/tools/tool.service';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { PagesState } from '../../pages.model';
import { ChatState } from 'src/app/chat/chat.model';
import { MetaPageService } from 'src/app/canvas/meta-page.service';
import { DomSanitizer } from '@angular/platform-browser';
import { fitImgInTarget } from 'src/app/utils';

@Component({
  selector: 'images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent extends CanvasComponent {

  img_src: any | undefined
  img_index = 0;
  // img_no: number = 0
  num_imgs: number = 0
  bShowSlide: boolean = false
  view_box_width: number = 0 //We need to save them in the asset
  view_box_height: number = 0
  bShowHeader: boolean = true
  bNoHeader: boolean = false
  @ViewChild('outComponent') outComponent: ElementRef | undefined
  @ViewChild('container') container: ElementRef | undefined
  @ViewChild('img') img: ElementRef | undefined

  container_height: number = 500;
  container_width: number = 500;
  container_top: number = 0;
  container_left: number = 0;
  bSmall: boolean = false
  constructor(
    public canvasStore: Store<CanvasState>,
    public wssState: Store<WSSState>,
    public wss_service: WSSService,
    public canvas_service: CanvasService,
    public pagesState: Store<PagesState>,
    public wss_serivce: WSSService,
    public chatState: Store<ChatState>,
    public tools_service: ToolService,
    public meta_server: MetaPageService,
    public sanitizer: DomSanitizer,
  ) {
    console.log("images")
    super(canvasStore, wssState, wss_service, canvas_service, pagesState, wss_serivce, chatState, tools_service, meta_server)
  }

  onImageLoad($event: any) {
    console.log("image loaded")
    let ns = this.setSize()

    this.container_height = ns.height
    this.container_width = ns.width
    this.container_top = ns.top
    this.container_left = ns.left
    console.log("images container_width " + this.container_width)

    if (this.container) {
      this.addCanvasToDiv(true, this.container.nativeElement, this.page_svg, ns.width, ns.height)
    }
  }


  gotAsset() {

    if (this._asset.images) {
      this.num_imgs = this._asset.images.length
      if (this.num_imgs > 0) {
        setTimeout(async () => {
          try {
            this.img_src = await this.tools_service.getKeyURL(this._asset.images[this.img_index].key)
            setTimeout(() => {
              this.onResize()
            }, 100)

          } catch (e) {
            console.error(e + " " + "images")
          }
        })
      } else {

      }
    } else if (this._asset.url) {
      this.img_src = this.sanitizer.bypassSecurityTrustResourceUrl(this._asset.url);
    } else {
      console.error("image asset has no images")
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(_event?: any) {
    this.removeInfoAndAnchors()
    this.removeMultiSelBoxes()
    let ns = this.setSize()
    setTimeout(() => {
      this.container_height = ns.height
      this.container_width = ns.width
      this.container_top = ns.top
      this.container_left = ns.left
      this.my_canvas.style.width = ns.width + "px"
      this.my_canvas.style.height = ns.height + "px"

    })
  }
  ngAfterViewInit() {
    this.marging_top = 0;
    this.subscriptions.push(this.canvasStore.select(canvasOpts).subscribe((opts: any) => {
      if (opts) {
        if (opts.bContract) {
          this.bNoHeader = true;
          return
        }
        this.bNoHeader = false
      }
    }))
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (this._asset) {
          if (this._asset.asset_id == msg.asset_id) {
            if (msg.hasOwnProperty("img_index")) {
              this.select(msg.img_index, false)
            }
          }
        }
      }
    }))

    this.subscriptions.push(this.canvasStore.select(saveSVG).subscribe((send_to: string) => {
      if (send_to == "asset") {
        let svg = this.draw_tool.svg(true)
        if (this._asset) {
          let asset = Object.assign({}, this._asset)
          asset.svg = svg
          asset.view_box_width = this.view_box_width
          asset.view_box_height = this.view_box_height
          this.tools_service.changeAsset(asset)
        }
      }
    }))
    if (this.outComponent) {
      this.view_box_width = this.outComponent.nativeElement.clientWidth
      this.view_box_height = this.outComponent.nativeElement.clientHeight

      let page_svg: any | undefined
      if (this._asset) {
        if (this._asset.svg) {
          if (this._asset.svg[this.img_index]) {
            page_svg = this._asset.svg[this.img_index]
          }
        }
        if (this._asset.view_box_width) {
          this.view_box_width = this._asset.view_box_width
          this.view_box_height = this._asset.view_box_height
        }
      }
      console.log("images component " + this.view_box_width + " " + this.view_box_height)
      setTimeout(() => {
        let ns = this.setSize()

        this.container_height = ns.height
        this.container_width = ns.width
        this.container_top = ns.top
        this.container_left = ns.left
        if (ns.width + ns.height == 0) {
          setTimeout(() => {
            let ns = this.setSize()
            this.container_height = ns.height
            this.container_width = ns.width
            this.container_top = ns.top
            this.container_left = ns.left
          }, 1000)
        }
        if (this.container) {
          this.addCanvasToDiv(true, this.container.nativeElement, page_svg, ns.width, ns.height)
        }
      })
    }
    this.onResize()
  }
  setSize() { //keps a .75 aspect ratio

    let width = 0
    let top = 0;
    let left = 0
    let height = 0
    let out_w = 0
    let out_h = 0
    if (this.img && this.outComponent && this.container) {
      this.bSmall = this.outComponent.nativeElement.clientHeight < 200
      out_w = this.outComponent.nativeElement.clientWidth
      out_h = this.outComponent.nativeElement.clientHeight
      height = out_h
      // if (this.outComponent.nativeElement.clientHeight > 200) {
      // out_h -= 40
      // }
      let img_w = this.img.nativeElement.naturalWidth
      let img_h = this.img.nativeElement.naturalHeight

      let rv = fitImgInTarget(img_w, img_h, out_w, out_h)
      // if (this.outComponent.nativeElement.clientHeight > 200) {
      //   rv.top += 40
      // }
      return rv;
    }
    return {
      height: height,
      width: width,
      top: top,
      left: left
    }
  }
  selImage(page: number) {
    this.img_index = page - 1
    this.select(this.img_index, true)
  }

  gallery() {
    this.bShowSlide = !this.bShowSlide
  }
  select(i: number, bForwrd: boolean) {
    setTimeout(async () => {
      try {
        this.img_index = i;
        this.img_src = await this.tools_service.getKeyURL(this._asset.images[this.img_index].key)
        let jmsg = {
          img_index: this.img_index,
          asset_id: this._asset.asset_id
        }
        if (bForwrd) {
          this.wss_service.sendMessageToOtherMembers(jmsg)
        }
      } catch (e) {
        console.error(e + " " + "images select")
      }
    })
  }
}
