import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CodeFromURL, IsPhone, NotifyError, NotifyWait, ShowError } from './pages/pages.actions';
import { PagesState } from './pages/pages.model';
import { getQueryParameter, initQueryParameters } from './utils';
import { askHangUp, callWaiting, customizeAccount, debug, gotCode, gotConverge, gotInvoices, hasGoogleCalendar, notifyUsr, recStatus, showAsset, showCC, showWelcome } from './pages/pages.selectors';
import { WSSService } from './wss/wss.service';
import { KVideoService } from './k-video/k-video.service';
import { WRTCService } from './wrtc/wrtc.service';
import { CalendarState } from './pages/scheduling/calendar.model';
import { changedTZ, newAppointment } from './pages/scheduling/calendar.selectors';
import { ContactsService } from './pages/contacts/contacts.service';
import { AddAppointment } from './pages/scheduling/calendar.actions';
// import { takePic } from './k-pdf/pdf.selectors';
import { CanvasState } from './canvas/canvas.model';

import { ControlsState } from './controls/controls.model';
// import { feedBack } from './controls/controls.selectors';
import { SelectToolOpts } from './canvas/canvas.actions';
import { canvasOpts, getInitials, getSignature, goToStep, selectedTool, selectToolOptions } from './canvas/canvas.selectors';
import { ChatService } from './chat/chat.service';
import { ChapalaMLSService } from './chapala-mls/chapala-mls-service';
import { RecordService } from './record/record.service';
import { KVideoState } from './k-video/k-video.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WSSState } from './wss/wss.model';
import { callId, sessionExpired } from './wss/wss.selectors';
import { CalendarService } from './pages/scheduling/calendar.service';
import { KeyziiService } from './wss/keyzii.service';
import { RecaptchaService } from './wss/recaptcha.service';
// import { MRecordService } from './record/m_record.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent implements OnInit {
  bShowLeftMenu: boolean = true
  title = 'keyzii';


  event: any | undefined
  event_date: Date | undefined
  pic_target: string | undefined//




  bGetSignature: boolean = false
  bGetInitials: boolean = false
  show_tool: string | undefined
  opt_params: any = {}
  show_tool_left: number = 0
  show_tool_top: number = 110
  bShowFeedBack: boolean = false;

  bShowingPDF: boolean = false
  bShowAppHeader: boolean = false;
  // bShowAssetHeader: boolean = false;
  // canvas_opts: any

  //Reference lines
  debug: string = "lines"
  isOpen: boolean = false;

  bShowUserNorification: boolean = false
  notify: any | undefined
  subscriptions: any;
  closedIt($event: boolean) {
    this.isOpen = $event
  }

  bSessioExpired: boolean = false

  welcome: string | undefined
  is_chrome: boolean = true
  bShowTZ: boolean = false
  // ask_code_from: string | undefined
  scroll_x: number = 0
  show_cc: string | undefined
  bAskHangUp: boolean = false
  bShowFreeEnd: boolean = false
  bHasCC: boolean = false

  bShowKpayWnd: boolean = false
  call_id: string | undefined
  code: any
  constructor(
    private pagesState: Store<PagesState>,
    public router: Router,
    private titleService: Title,
    private wss_service: WSSService, //Make sure the service is ready
    private k_video_service: KVideoService, //Make sure the service is ready
    private wrtc_service: WRTCService,
    public calendarStore: Store<CalendarState>,
    public contacts_service: ContactsService,
    public canvasStore: Store<CanvasState>,
    public controlsState: Store<ControlsState>,
    private chat_service: ChatService,
    private chapala_mls_service: ChapalaMLSService,
    private rec_service: RecordService,//MRecordService, //April 13
    private wssState: Store<WSSState>,
    private calendar_service: CalendarService,
    public recaptcha_service: RecaptchaService
  ) {
    console.log("app component")
    initQueryParameters()

    let from = getQueryParameter("from")
    if (from == "rockethub") {
      this.pagesState.dispatch(new CodeFromURL(from))
    }

    this.calendarStore.select(changedTZ).subscribe((tz: any) => {
      if (tz && tz.text) {
        let lastNoAsk = localStorage.getItem('last_no_ask')
        if (lastNoAsk != tz.text) {
          setTimeout(() => { this.bShowTZ = true })
        }
      }
    })
    this.wssState.select(sessionExpired).subscribe((at: number) => {
      if (at > 0) {
        this.bSessioExpired = true
      }
    })

    this.pagesState.select(showCC).subscribe((show_cc: string | undefined) => {
      if (show_cc) {

        this.show_cc = show_cc
        if (this.show_cc == 'kpay-invoice') {
          this.bShowKpayWnd = true;
        }
      } else {
        if (this.show_cc == 'kpay-invoice') {
          this.bShowKpayWnd = false;
        }
        this.show_cc = undefined
      }

    })
    // this.pagesState.select(askCodeFrom).subscribe((ask_code_from: string | undefined) => {
    //   this.ask_code_from = ask_code_from;
    // })
    this.pagesState.select(askHangUp).subscribe((bAskHangUp: boolean) => {
      this.bAskHangUp = bAskHangUp;
    })

    this.pagesState.select(showWelcome).subscribe((who: string | undefined) => {
      if (who) {
        if (who != "no") {
          let count: string | null = localStorage.getItem("welcome_cont")
          if (!count) {
            count = "1"
          } else {
            if (count == "10") {
              setTimeout(() => { this.welcome = who })
            } else {
              let v = parseInt(count) + 1
              localStorage.setItem("welcome_cont", v.toString())
            }
          }
        }
      }
    })

    this.pagesState.select(notifyUsr).subscribe((notify: any | undefined) => {
      setTimeout(() => { this.notify = notify })
    })
    // this.pagesState.dispatch(new NotifyError("This is a test of the system"))

    this.pagesState.select(customizeAccount).subscribe((customize: any) => {
      if (customize) {
        const favicon = document.getElementById("favicon");
        if (favicon) {
          favicon.setAttribute("href", customize.fav_icon);
        }
        if (customize.tab_name) {
          this.title = customize.tab_name
          this.titleService.setTitle(this.title)
        }
      }
    })

    this.pagesState.select(debug).subscribe((debug: string) => {
      this.debug = debug
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      this.call_id = call_id
      if (!call_id) {
        this.bShowKpayWnd = false;

      }
    })

    this.pagesState.select(showAsset).subscribe((asset: any) => {
      this.bShowingPDF = false
      if (asset) {
        if (asset.type == "pdf" || asset.type == "contract") {
          this.bShowingPDF = true
        }
        if (!asset.asset_id) {
          this.canvasStore.dispatch(new SelectToolOpts("a1"))
        }
        // this.setHeader()
      }
    })
    this.canvasStore.select(goToStep).subscribe(async (step: any) => {
      if (step) {
        if (step.tool) {
          this.canvasStore.dispatch(new SelectToolOpts(step.tool))
        }
      }
    })
    this.canvasStore.select(selectToolOptions).subscribe((opt: any) => {
      setTimeout(() => {
        if (opt.name == "h2" && this.show_tool == "view_chat") {
          return
        }
        this.show_tool = opt.name
        if (opt.params) {
          this.opt_params = opt.params
        }
        else {
          this.opt_params = {}
        }
        if (opt && opt.params && opt.params.x) {
          this.show_tool_left = opt.params.x + this.scroll_x
        } else {
          this.show_tool_left = 0
        }
        if (opt && opt.params && opt.params.y) {
          this.show_tool_top = opt.params.y
        } else {
          this.show_tool_top = 110
        }
      })
    })


    this.calendarStore.select(newAppointment).subscribe((slot: any) => {
      if (slot) {
        this.event_date = slot.time
        this.event = {}
      } else {
        delete this.event
        delete this.event_date
      }
    })

    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.setHeader()
    //   }
    // })
    // this.canvasStore.select(canvasOpts).subscribe((opts: any) => {
    //   this.canvas_opts = opts
    //   this.setHeader()
    // })
    this.pagesState.select(gotConverge).subscribe((converge: any) => {
      if (converge && converge.cards && converge.cards.length > 0) {
        let card = converge.cards[converge.select]
        card.ssl_exp_date //0724
        var cc_y = parseInt("20" + card.ssl_exp_date.slice(-2));
        let cc_m = parseInt(card.ssl_exp_date.slice(0, 2));

        let date = new Date()
        let mm = date.getMonth() + 1
        let yy = date.getFullYear()
        if (yy > cc_y) {
          return
        }
        if (yy == cc_y && mm > cc_m) {
          return
        }
        this.bHasCC = true;
        this.bShowFreeEnd = false;
      }
    })
    this.pagesState.select(gotCode).subscribe((code: any) => {
      if (code) {
        this.code = code;
        this.bShowFreeEnd = false;
      }
    })
    this.pagesState.select(gotInvoices).subscribe((invoices: any[]) => {
      if (invoices && invoices.length > 0 && !this.bHasCC) {
        let last = invoices[0]
        let now = new Date().getTime()
        if (last.free_trial) {
          let dif_ms = new Date(last.next_date).getTime() - now
          let dif_days = dif_ms / (24 * 60 * 60 * 1000)

          if (dif_days < 7 && !this.bHasCC && !this.code) {
            this.bShowFreeEnd = true;
          }
        } else {
          let dif_ms = now - new Date(last.created_at).getTime()
          let dif_days = dif_ms / (24 * 60 * 60 * 1000)

          if (last.overdue && dif_days > 5 && last.amount > 0) {
            this.bShowFreeEnd = true;
          }
        }
      }
    })

  }
  /*  setHeader() {
      this.canvasStore.dispatch(new SelectToolOpts(""))
      if (this.canvas_opts) {
        if (this.canvas_opts.bContract) {
          this.bShowAppHeader = false
          this.bShowAssetHeader = true
          return
        }
      }
  
  
      if (!this.bShowingPDF && this.router.url != '/login' && this.router.url != '/signup') {
        this.bShowAppHeader = true
        this.bShowAssetHeader = false
      } else if (this.router.url == '/call') {
        //if the asset is a pdf or a contract
        if (this.bShowingPDF) {
          this.bShowAppHeader = false
          this.bShowAssetHeader = true
        } else {
          this.bShowAppHeader = false
          this.bShowAssetHeader = false
        }
      } else {
        this.bShowAppHeader = false
        this.bShowAssetHeader = false
      }
    }*/
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    let isPhone = window.innerWidth < 700
    this.pagesState.dispatch(new IsPhone(isPhone))
    setTimeout(() => this.bShowLeftMenu = !isPhone)
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event?: any) {

    this.scroll_x = window.scrollX
    console.log("Scroll " + this.scroll_x)
  }

  async   ngAfterViewInit() {

    const agent = window.navigator.userAgent.toLowerCase();
    this.is_chrome =
      agent.indexOf('edg') > -1 //? 'Chromium-based Edge'
      || agent.indexOf('opr') > -1// ? 'Opera'
      || agent.indexOf('chrome') > -1// ? 'Chrome'

    this.onResize()

  }
  ngOnInit() {

  }

  eventChange() {
    delete this.event
    this.calendarStore.dispatch(new AddAppointment(undefined))
    console.log("Send event")
  }
}
