import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { GotMessage } from 'src/app/wss/wss.actions';
import { WSSState } from 'src/app/wss/wss.model';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { PayService } from '../pay.service';

@Component({
  selector: 'kpay-bar',
  templateUrl: './kpay-bar.component.html',
  styleUrls: ['./kpay-bar.component.scss']
})
export class KpayBarComponent {
  progress: any = {
    plan: 0,
    det: 0,
    pay: 0,
    status: 0
  }
  plan_progres: number = 0;
  det_progres: number = 0;
  pay_progres: number = 0;
  status_progres: number = 0;
  step: string = "plan"
  // cap_token: string | undefined
  amount: number = 0
  payment_lbl: string = "Payment Status"
  constructor(
    public wssState: Store<WSSState>,
    public pay_service: PayService, //Make sure the service is ready
    public pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("kpay-bar")

  }
  ngAfterViewInit() {
    this.pay_service.openPay("acc")
    //steps acc, plan, bill, process, done |declined|error

    this.wssState.select(lastMsg).subscribe(async (msg: any) => {
      if (msg) {
        if (msg.hasOwnProperty('k_pay')) {
          if (this.step != msg.k_pay) {
            if (msg.k_pay == "subscribe") {
              await this.pay_service.saveCCSubscription(msg.token, msg.bill_info, msg.plan, msg.acc)
            } else if (msg.k_pay == "progress") {
              this.progress[msg.steep] = msg.progress
            }
            // else if (msg.k_pay == 'payment') { //done by the kpay-invoice bar
            //   try {
            //     this.pay_service.forwardPayment(msg.payment)
            //   } catch (e: any) {
            //     console.error(e)
            //   }
            // }
            else {
              this.step = msg.k_pay
              if (this.step == 'get_pay_token') {
                if (msg.cap_token) {
                  this.pay_service.sendPayAuthToken(msg.cap_token, msg.amount)
                }
              } else if (this.step == 'declined') {
                this.step = "last"
                this.payment_lbl = "Declined"
              } else if (this.step == 'error') {
                this.step = "last"
                this.payment_lbl = msg.error
              } else if (this.step == 'done') {
                this.step = "last"
                if (msg.amount && parseInt(msg.amount) > 0) {
                  this.payment_lbl = "Payment for " + msg.amount + " received"
                } else {
                  this.payment_lbl = "CC Token saved "
                }
              } else if (this.step == 'close') {
                this.wssState.dispatch(new GotMessage({})) //clear the msg
                this.pagesState.dispatch(new ShowCC(undefined))
              }
            }
          }
        }
      }
    })
  }
}
