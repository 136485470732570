/*
Copyright(c) 2022 Sergio A. Fernandez, Ph.D.
*/

import { WSSAction, WSSActionTypes } from "./wss.actions";
import { WSSState, initialWSSState } from "./wss.model";


// let streams_cache: any = {}
export function wssReducer(state = initialWSSState,
  action: WSSAction): WSSState {
  switch (action.type) {

    case WSSActionTypes.GotMessage: {
      // console.log("got messge")
      const wssState: WSSState = Object.assign({}, state);
      wssState.last_msg = action.msg
      return wssState;
    }
    case WSSActionTypes.SessionId: {
      const wssState: WSSState = Object.assign({}, state);
      wssState.session_id = action.id
      return wssState;
    }
    case WSSActionTypes.SessionExpired: {
      const wssState: WSSState = Object.assign({}, state);
      wssState.session_expired = new Date().getTime()
      return wssState;
    }

    /*
    accepted_eula_on
    bShowing24
    customer_id
    email
    host_url
    roll
    url
    work_time
    _id
    */
    case WSSActionTypes.LogedIn: {
      const wssState: WSSState = Object.assign({}, state);
      wssState.customer_id = action.rv.customer_id
      wssState.my_id = action.rv._id
      return wssState;
    }
    case WSSActionTypes.Busy: {
      const wssState: WSSState = Object.assign({}, state);
      wssState.busy = action.yes
      return wssState;
    }
    case WSSActionTypes.HangUp: {
      const wssState: WSSState = Object.assign({}, state);
      delete wssState.call_id
      delete wssState.caller_id
      delete wssState.rep_id
      delete wssState.manager_id
      return wssState;
    }

    case WSSActionTypes.InCall: {
      console.log("in-call ")
      const wssState: WSSState = Object.assign({}, state);
      if (action.call) {
        wssState.call_id = action.call.call_id

        wssState.rep_id = action.call.rep_id

        wssState.caller_id = action.call.caller_id
        // wssState.caller_video = action.call.caller_video
        // wssState.caller_audio = action.call.caller_audio

        wssState.manager_id = action.call.manager_id
        // wssState.manager_video = action.call.manager_video
        // wssState.manager_audio = action.call.manager_audio
      } else {
        wssState.call_id = undefined

        wssState.rep_id = undefined

        wssState.caller_id = undefined
        // wssState.caller_video = false
        // wssState.caller_audio = false

        wssState.manager_id = undefined
        // wssState.manager_video = false
        // wssState.manager_audio = false
      }

      return wssState;
    }
    case WSSActionTypes.LeftCall: {
      console.log("LeftCall " + action.who)
      const wssState: WSSState = Object.assign({}, state);
      wssState.left_call = action.who
      if (action.who == "caller") {
        delete wssState.caller_id
      }
      if (action.who == "manager") {
        delete wssState.manager_id
      }
      if (action.who == "rep") {
        delete wssState.rep_id
      }

      if (!wssState.caller_id) { //if the caller is not there
        if (wssState.my_id == wssState.rep_id) { //and I am the rep
          if (!wssState.manager_id) { //and the manager is not there
            delete wssState.call_id
            delete wssState.rep_id
          }
        } else { //I am the manager
          if (!wssState.rep_id) { //and the rep is not there
            delete wssState.call_id
            delete wssState.manager_id
          }
        }


      }
      return wssState;
    }
    default: {
      return state;
    }
  }
}
