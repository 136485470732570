import { Component } from '@angular/core';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ShowCC } from 'src/app/pages/pages.actions';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { KpayBarComponent } from '../kpay-bar/kpay-bar.component';

@Component({
  selector: 'kpay-invoice-bar',
  templateUrl: './kpay-invoice-bar.component.html',
  styleUrls: ['../kpay-bar/kpay-bar.component.scss', './kpay-invoice-bar.component.scss']
})
export class KpayInvoiceBarComponent extends KpayBarComponent {
  contact_info: number = 0
  progress: any = {
    contact: 0,
    invoice: 0,
    bill: 0,
    process: 0,
    last: 0
  }
  ngAfterViewInit() {
    console.log("invoce bar")
    this.pay_service.openPay("contact")
    this.step = "invoice"

    this.wssState.select(lastMsg).subscribe((msg: any) => {
      if (msg) {
        if (msg.hasOwnProperty('k_pay')) {
          setTimeout(async () => {
            if (this.step != msg.k_pay) {
              if (msg.k_pay == "payment") {
                await this.pay_service.savePayment(msg)
              } else if (msg.k_pay == "progress") {
                this.progress[msg.steep] = msg.progress
              } else {
                this.step = msg.k_pay
                if (this.step == 'contac_ready') {
                  this.pay_service.contactReady(msg)
                } else if (this.step == 'get_pay_token') {
                  if (msg.cap_token) {
                    this.pay_service.sendPayAuthToken(msg.cap_token, msg.amount)
                  }
                } else if (this.step == 'declined') {
                  this.step = "last"
                  this.payment_lbl = "Declined"
                } else if (this.step == 'error') {
                  this.step = "last"
                  this.payment_lbl = msg.error
                } else if (this.step == 'done') {
                  this.step = "last"
                  if (msg.amount && parseInt(msg.amount) > 0) {
                    this.payment_lbl = " processed " + msg.amount
                  } else {
                    this.payment_lbl = "Payment processed"
                  }
                } else if (this.step == 'close') {
                  this.pagesState.dispatch(new ShowCC(undefined))
                }
              }
            }
          })
        }
      }
    })
  }
}
