<div class="out">

  <iframe #frame class="frame" [ngClass]="{s_fr:bAllowDrawing}" width="100%" height="640" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" [src]="kuula_src"></iframe>
  <div *ngIf="my_roll!=channel_owner" class="content scr" (mousedown)="getChannel()">
    <div class="abs_center">
    </div>
  </div>
  <video-canvas class="content " *ngIf="bAllowDrawing" [rv_l]="rv_l" [rv_r]="rv_r" roll="kuula"></video-canvas>

  <div *ngIf="!bPreview" class="video_canvas" [ngClass]="{yellow:bAllowDrawing}">
    <img src="assets/images/pencil.svg" alt="" class="pen">
    <switch class="switch" [checked]="bAllowDrawing" (checkedCahnge)="toogleCanvas($event)"></switch>
  </div>
  <!-- <div class="rv_x" *ngIf="rv_l>0" [style.left.px]="rv_l">

  </div>
  <div class="rv_x" *ngIf="rv_r>0" [style.left.px]="rv_r">

  </div> -->
</div>
