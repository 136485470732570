import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Download,
  GetPDFSVG,
  SelectToolOpts,
  SelSigner,
  SetTool, UnDo,
} from 'src/app/canvas/canvas.actions';
import { ShowAsset } from 'src/app/pages/pages.actions';

import { PagesState } from 'src/app/pages/pages.model';
import { isPhone, showAPI, showAsset } from 'src/app/pages/pages.selectors';
import { ToolService } from 'src/app/tools/tool.service';
import { WSSService } from 'src/app/wss/wss.service';
import { selectedTool, selectToolOptions, selShape, selSignerKey, shapeOptions, showDrawTools, signers, signerSteps, toolsChanged, undoStackSize } from '../canvas.selectors';
import { CanvasService } from '../canvas.service';


/*
shows when the showAssetHeader selector is true
the ShowAssetHedaer is dispatched on when the route is /call or /assets
and th eselected_asset has an asset( when they have an open asset ),
turned off on navigation by the left-menu
Its also turned of when the selcted asset is undefined
*/

@Component({
  selector: 'assets-header',
  templateUrl: './assets-header.component.html',
  styleUrls: ['./assets-header.component.scss']
})
export class AssetsHeaderComponent {
  signers: any = {}
  signer_key: string = "";
  signer_lbl: string = "Add new contract signer"
  signer_color: string = "#000"
  signer_border: string = "1px solid black"
  isPhone: boolean = false;
  asset_name: string = ""

  asset: any

  take_pic: boolean = false;
  driver_lic: boolean = false;
  color: string = "rgba(255,255,0,0.53)"//"#ffff00"
  width: number = 5
  bIsSigner: boolean = false;


  back_colors: any = {
    none: "",
    undo: "",
    move: "",
    erase: "",
    shapes: "",
    pencil: "",
    drop_text: "",
    drop_signature: "",
    comment: "",
    cloud: "",
    settings: "",
    colors: ""
  }
  color_keys: string[] = []
  bIsInitials: boolean = false;
  opened_tool: string = ""

  shape_icon: string = "shapes.svg"
  sel_shape: number = -1
  shape_options: any[] = []
  undo_stack_size: number = 0
  selected_tool: string = "none"
  bShowSendEmails: boolean = false;
  bIsContract: boolean = false;
  @Input() bShowSigners: boolean = true
  @Input() bShowInputFields: boolean = true
  sharing_scr: boolean = false
  @Input() set bSharingScr(v: boolean) {
    this.sharing_scr = v
    this.bShowSigners = !this.sharing_scr;
  }
  bShowDrawTools: boolean = true

  subscriptions: any[] = []
  asset_type: string = ""
  constructor(
    public pagesState: Store<PagesState>,
    public canvas_service: CanvasService,
    public wss_service: WSSService,
    public canvasStore: Store<CanvasState>,
    private tools_service: ToolService,
  ) {
    console.log("asset header")
    this.color_keys = Object.keys(this.back_colors)
    let back_colors = Object.assign({}, this.back_colors)
    this.color_keys.forEach(key => {
      let me: any = this
      back_colors[key] = "transparent"
    });
    setTimeout(() => {
      this.back_colors = back_colors
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }

  ngOnInit() {
    this.subscriptions.push(this.canvasStore.select(showDrawTools).subscribe((show_draw_tools: boolean) => {
      this.bShowDrawTools = show_draw_tools
    }))

    this.subscriptions.push(this.pagesState.select(showAPI).subscribe((show: boolean) => {
      if (show) {
        this.bShowDrawTools = false
      }

    }))
    this.subscriptions.push(this.canvasStore.select(undoStackSize).subscribe((undo_stack_size: number) => {
      this.undo_stack_size = undo_stack_size
      if (this.undo_stack_size == 0 && this.selected_tool == "undo") {
        this.canvasStore.dispatch(new SetTool("undo"))
      }
    }))

    this.subscriptions.push(this.canvasStore.select(selectToolOptions).subscribe((tool_options: any) => {
      if (tool_options) {
        this.opened_tool = tool_options.name
        console.log("got selectToolOptions " + this.opened_tool)
      }
    }))

    this.subscriptions.push(this.canvasStore.select(shapeOptions).subscribe((shape_options: any) => {
      this.shape_options = shape_options
    }))

    this.subscriptions.push(this.canvasStore.select(selShape).subscribe((sel_shape: number) => {
      this.sel_shape = sel_shape
      let opt = this.shape_options[this.sel_shape]
      if (opt) {
        this.shape_icon = opt.svg
        this.canvasStore.dispatch(new SetTool(opt.tool))
        this.canvasStore.dispatch(new SelectToolOpts("h1"))
        this.opened_tool = ""
      }
    }))

    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      setTimeout(() => this.isPhone = isPhone);
    }))
    this.subscriptions.push(this.canvasStore.select(signers).subscribe((signers: any) => {
      this.signers = signers
      let keys = Object.keys(this.signers)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let signer = this.signers[key]
        if (signer.email) {
          this.bShowSendEmails = true;
          if (!this.signer_key) {
            this.canvasStore.dispatch(new SelSigner(key))
          }
          break
        }
      }
      if (!this.bShowSendEmails && !this.signer_key) {
        this.canvasStore.dispatch(new SelSigner(keys[0]))
      }
      this.setSignerLbl()

    }))
    this.subscriptions.push(this.canvasStore.select(selectedTool).subscribe((tool_name: string) => {
      this.selected_tool = tool_name
      this.canvasStore.dispatch(new SelectToolOpts("h2"))
      this.opened_tool = ""
      if (tool_name == 'drop_initials') {
        this.bIsInitials = true;
        this.opened_tool = ""
      } else if (tool_name == 'drop_signature') {
        this.bIsInitials = false;
        this.opened_tool = ""
      }
      console.log("asset header")
      //The selected tool will have color
      this.setBackground(tool_name)
    }))

    this.subscriptions.push(this.canvasStore.select(selSignerKey).subscribe((signer_key: string | undefined) => {
      if (signer_key) {
        this.signer_key = signer_key
        this.setSignerLbl()
      }
    }))
    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      setTimeout(() => {
        if (asset) {
          this.asset_type = asset.type
          this.asset = asset
          if (asset.name) {
            this.asset_name = asset.name
          } else {
            this.asset_name = asset.type
          }
          if (asset.type == "contract") {
            this.bIsContract = true
            this.bShowSigners = true
          } else {
            this.bIsContract = false
            this.bShowSigners = false
          }
          this.bShowDrawTools = (
            asset.type == "white_board"
            || asset.type == "pdf"
            || asset.type == "contract"
            || asset.type == "image"
            || asset.type == "video_canvas")
        }
      })
    }))
    this.subscriptions.push(this.canvasStore.select(toolsChanged).subscribe((tools: any) => {
      if (tools) {
        if (tools.pencil) {
          this.color = tools.pencil.color
          this.width = tools.pencil.width
        }
      }
    }))
    this.subscriptions.push(this.canvasStore.select(signerSteps).subscribe((steps: any) => {
      if (steps) {
        this.bIsSigner = Object.keys(steps).length > 0
      }
    }))
  }
  setSignerLbl() {
    let signer = this.signers[this.signer_key]
    if (signer) {
      let coma = ""
      this.signer_lbl = signer.title
      if (signer.name) {
        if (signer.name.length > 0) {
          this.signer_lbl = signer.name
          coma = ", "
        }
      }
      if (signer.email) {
        if (signer.email.length > 0) {
          this.signer_lbl += coma + signer.email

        }
      }
      if (this.signer_lbl.length < 1) {
        this.signer_lbl = signer.title
      }
      this.signer_color = signer.color
      this.signer_border = "1px solid " + signer.color

    }
  }

  none() {
    this.canvasStore.dispatch(new SelectToolOpts("h3"))
    this.canvasStore.dispatch(new SetTool("none"))
  }
  undo() {
    this.canvasStore.dispatch(new SelectToolOpts("h4"))
    this.canvasStore.dispatch(new SetTool("undo"))
    this.canvasStore.dispatch(new UnDo())
  }
  erase() {
    console.log("earse")
    this.canvasStore.dispatch(new SelectToolOpts("h5"))
    this.canvasStore.dispatch(new SetTool("erase"))
  }

  pen() {
    //if the select signer does not have email open the tool
    let signer = this.signers[this.signer_key]

    if (!signer) {//|| !signer.email
      this.canvasStore.dispatch(new SelectToolOpts("sel-signer1"))
      return
    }
    if (this.bIsInitials) {
      this.canvasStore.dispatch(new SetTool("drop_initials"))
    } else {
      this.canvasStore.dispatch(new SetTool("drop_signature"))
    }
  }

  pencil() {
    this.canvasStore.dispatch(new SelectToolOpts("h6"))
    this.canvasStore.dispatch(new SetTool("pencil"))
    this.setBackground("pencil")
  }
  text() {
    this.canvasStore.dispatch(new SelectToolOpts("h7"))
    this.canvasStore.dispatch(new SetTool("drop_text"))
    this.setBackground("drop_text")
  }
  shapes() {
    this.canvasStore.dispatch(new SelectToolOpts("h8"))
    let opt = this.shape_options[this.sel_shape]
    if (opt) {
      this.canvasStore.dispatch(new SetTool(opt.tool))
    } else {
      this.sel_shape = 0
      let opt = this.shape_options[this.sel_shape]
      if (opt) {
        this.shape_icon = opt.svg
        this.canvasStore.dispatch(new SetTool(opt.tool))
        this.canvasStore.dispatch(new SelectToolOpts("h9"))
        this.opened_tool = ""
      }
    }
  }

  comment() {
    this.canvasStore.dispatch(new SelectToolOpts("ha"))
    this.canvasStore.dispatch(new SetTool("comment"))
  }
  // cloud() {
  //   this.canvasStore.dispatch(new SelectToolOpts("sel-download"))
  //   // this.canvasStore.dispatch(new GetPDFSVG("download"))
  // }
  selSigner($event: any) {
    $event.stopPropagation()
    if (this.opened_tool == 'sel-signer') {
      this.canvasStore.dispatch(new SelectToolOpts("hb"))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("sel-signer"))
    }
  }
  selInput($event: any) {
    $event.stopPropagation()
    if (this.signer_key.length == 0) {
      return
    }
    if (this.opened_tool == 'sel-input') {
      this.canvasStore.dispatch(new SelectToolOpts("hb"))
    } else {
      this.canvasStore.dispatch(new SelectToolOpts("sel-input"))
    }
  }

  settings() {
    this.canvasStore.dispatch(new SelectToolOpts("pdf-opts"))
  }
  selectTool($event: any, name: string) {
    var viewportOffset = $event.srcElement.getBoundingClientRect();

    if (this.opened_tool == name) {
      this.opened_tool = ""
      this.canvasStore.dispatch(new SelectToolOpts(""))
    } else {
      this.opened_tool = name
      this.canvasStore.dispatch(new SelectToolOpts(name, { x: viewportOffset.left, y: 112 }))
      console.log("dispatch SelectToolOpts " + name)
      this.setBackground(name)
    }
  }
  setBackground(tool_name: string) {
    if (tool_name) {
      let back_colors = Object.assign({}, this.back_colors)
      this.color_keys.forEach(key => {
        let me: any = this
        let color = "transparent"
        if (tool_name == key
          || key == 'shapes' && (
            tool_name == 'rectangle'
            || tool_name == 'eclipse'
            || tool_name == 'polyfill'
            || tool_name == 'polygon'
            || tool_name == 'line'
            || tool_name == 'arrow'
          )
          || key == 'drop_signature' && tool_name == 'drop_initials'
          || key == 'settings' && tool_name == 'pdf-opts'
          || key == 'cloud' && tool_name == 'sel-download') {
          color = "#FFCE38"
        }
        back_colors[key] = color
      });
      setTimeout(() => {
        this.back_colors = back_colors
      });
    }
  }
  sendInvites() {
    this.canvas_service.sendPDFEmails()
  }
  down() {
    this.canvasStore.dispatch(new Download())
    this.canvasStore.dispatch(new SelectToolOpts(""))
  }
}
